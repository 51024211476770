import * as React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import "./contacts.scss"

const Contact = props => {
  let teamPictures = () => {
    const imagesQueries = useStaticQuery(graphql`query MyTeamQuery {
      allImageSharp {
        
        nodes {
          id
          parent {
            id
            ... on File {
              id
              name
              extension
              relativePath
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }`)
    const images = imagesQueries.allImageSharp.nodes;
    images.filter((image) => image.parent.relativePath.startsWith('team'))
    const imgObject = {}
    images.forEach((value)=>{imgObject[value.parent.name] = value.parent.childImageSharp})
    return imgObject ;
  }
  const pictures = teamPictures()
  const list = [
    // {
    //   nom:"Rachid Boukhlifa", 
    //   poste:"Directeur Commercial",
    //   mail: "r.boukhlifa@picaro.video",
    //   image : getImage(pictures["rachid"]),
    //   tel: "+33 6 49 46 31 76",

    // },

    {
      nom:"Goulchen Le Meur",
      poste:"Réalisateur",
      mail: "g.lemeur@picaro.video",
      tel: "+33 6 70 94 84 96",
      image : getImage(pictures["goulchen"])
    },
]
  return (
    <div>
      {/* <h1 className="contactTitle">CONTACT</h1> */}
        <div className="contactWrapper">
        
         { list.map((contact, index)=>{
            return <div className="contactWrapper2" key={index}>
              
              <div  className="teamImage">
                {/* <GatsbyImage image={contact.image}></GatsbyImage> */}
              </div>
              <div className="nom">
                {contact.nom}
              </div>
              <div className="poste">
                {contact.poste}
              </div>
              <div className="mail_tel">
                {contact.mail}
              </div>
              <div className="mail_tel">
                {contact.tel}
              </div>
              

            </div>
          })}
        </div>
    </div>
  )
}
const Contacts = props => {
  return (
    <div>
      
      <div className="">
      
        <Contact/>        
      </div>
    </div>
  )
}
Contacts.propTypes = {
  aProp: PropTypes.string,
}

Contacts.defaultProps = {
  aProp: ``,
}

export default Contacts
